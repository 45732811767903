<template>
    <div class="container">
        <qy-top-nav title="商品评价" />
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
        >
            <van-cell-group inset v-for="item in dataList">
                <van-cell>
                    <div>
                        <div class="comment-goods">
                            <div class="block-inline img-block">
                                <van-image
                                        alt="用户头像"
                                        width="50"
                                        height="50"
                                        :src="getImgUrl(item.userAvatar)"
                                />
                            </div>
                            <div class="block-inline name-block">
                                <div>{{item.hideName == 1 ? "匿名用户"  :  item.userName}}</div>
                                <van-rate v-model="item.commentLevel" :size="14" readonly />
                            </div>
                            <div class="block-inline time-block">
                                {{item.commentTime}}
                            </div>
                        </div>
                    </div>
                    <!--评价内容-->
                    <div class="comment">
                        <span>{{item.comment}}</span>
                    </div>
                    <!--评价图片-->
                    <div class="comment-imgs" v-for="img in item.commentImgs">
                        <van-image
                                width="80"
                                height="55"
                                :src="getImgUrl(img)"
                        />
                    </div>
                    <div class="comment-goods-name">
                        {{item.goodsName + '   ' + item.goodsInstanceRemark}}
                    </div>
                </van-cell>
            </van-cell-group>
        </van-list>
    </div>
</template>

<script>
    import request from "@/utils/request";
    import { Toast } from 'vant';
    export default {
        name: "goodsComment",
        data() {
            return {
                loading:false,
                finished:false,
                dataList:[],
                pageSize: 10,
                pageNum: 1,
                goodsId:this.getUrlParam("goodsId")
            }
        },
        methods:{
            onLoad(){
                let that = this;
                request.post("/api/comment/queryGoodsCommentList",{
                    goodsId:this.goodsId,
                    pageNum:this.pageNum,
                    pageSize:this.pageSize,
                }).then(res => {
                    if(res.code == 0){
                        let data = res.data;
                        let hasNextPage = data.hasNextPage;
                        let list = data.list;
                        list.forEach(item => {
                            if(item.commentImg){
                                item.commentImgs = item.commentImg.split(',');
                                delete item.commentImg;
                            }else {
                                item.commentImgs = [];
                            }
                        });
                        that.dataList = list;
                        if(!hasNextPage){
                            that.finished = true;
                            that.pageNum = 1;
                        }else {
                            that.pageNum++;
                        }
                        that.loading = false;
                    }else {
                        Toast.fail(res.msg);
                    }
                });
            },
        },

    }
</script>

<style scoped>
    body{
        background: bisque;
    }
    div.container{
        margin: 0px;
        padding: 0px;
        font-size: 14px;
    }
    .img-block{
        width: 20%;
    }
    .name-block{
        width: 55%;
    }
    .time-block{
        width: 25%;
    }
    .block-inline{
        height: 50px;
        display: inline-block;
        /*line-height: 50px;*/
        float: left;
    }
    .comment-goods img{
        width: 50px;
        height: 50px;
    }
    .comment-imgs{
        display: inline-block;
        margin-right: 3px;
    }
    .comment-imgs img{
        width: 85px;
        height: 65px;
        padding: 5px 5px;
    }
    .comment-level span{
        position: relative;
        left:15px;
    }
    .comment-goods-name{
        color: #9c9c9c;
    }
</style>